/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The status of the Experience</p>
 */
export enum ExperienceStatus {
	Draft = 'Draft',
	Published = 'Published'
}

