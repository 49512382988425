import { RouterProvider, RouterProviderProps } from 'react-router-dom';
import { PostHogProvider } from '../../analytics';
import { AuthProvider } from '../auth';
import { ThemeProvider } from '../../theme';
import { RollbarProvider } from '../../errors';
import * as config from '../../config';

type Props = {
  router: RouterProviderProps;
  rollbarToken?: string;
};

export default function AppProvider({ router, rollbarToken }: Props) {
  const content = (
    <ThemeProvider>
      <RouterProvider {...router} />
    </ThemeProvider>
  );

  return (
    <AuthProvider>
      <PostHogProvider>
        {config.boxConfig.isLocal || !rollbarToken ? (
          content
        ) : (
          <RollbarProvider token={rollbarToken}>{content}</RollbarProvider>
        )}
      </PostHogProvider>
    </AuthProvider>
  );
}
