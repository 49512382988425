import { Breadcrumb as FlowbiteBreadcrumb } from 'flowbite-react';
import { useMatches, UIMatch, useNavigate, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Handle } from '../../router';
import { ArrowLeftIcon, ArrowRightIcon } from '../../icons';
import { sizeToPx } from '../../constant';
import { useScreenSize } from '../../hooks';

function Breadcrumb() {
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();
  const location = useLocation();
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);

  useEffect(() => {
    setCanGoBack(window.history.state?.idx > 0);

    const currentIndex = window.history.state?.idx;
    if (typeof currentIndex === 'number') {
      setCanGoForward(currentIndex < window.history.length - 1);
    } else {
      setCanGoForward(false);
    }
  }, [location]);

  const matches = useMatches() as UIMatch<unknown, Handle>[];
  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => {
      // This would never happen because of the filter above
      if (!match.handle?.crumb) {
        return {
          crumb: { title: 'Unknown', key: 'unknown' },
          path: '/',
        };
      }

      return {
        crumb: match.handle.crumb(match.data),
        path: match.pathname,
      };
    });

  return (
    <div className="flex flex-row items-center justify-between w-full overflow-hidden">
      <FlowbiteBreadcrumb className="flex-grow text-left overflow-x-auto whitespace-nowrap">
        {crumbs.map(({ crumb, path }) => {
          return (
            <FlowbiteBreadcrumb.Item key={path}>
              <Link to={path}>{crumb.title}</Link>
            </FlowbiteBreadcrumb.Item>
          );
        })}
      </FlowbiteBreadcrumb>
      {!isMobile && (
        <div className="flex items-center justify-end space-x-2">
          <ArrowLeftIcon
            className={classNames('size-4', {
              'cursor-pointer text-black-lighter hover:text-black-darker':
                canGoBack,
              'cursor-not-allowed text-grey': !canGoBack,
            })}
            onClick={() => {
              if (canGoBack) {
                navigate(-1);
              }
            }}
          />
          <ArrowRightIcon
            size={sizeToPx.xs}
            className={classNames('size-4', {
              'cursor-pointer text-black-lighter hover:text-black-darker':
                canGoForward,
              'cursor-not-allowed text-grey-darker': !canGoForward,
            })}
            onClick={() => {
              if (canGoForward) {
                navigate(1);
              }
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Breadcrumb;
