import {
  Article,
  Loader,
  Page,
  Section,
} from '@cg/module-frontend/src/components';
import { useAuth } from '@cg/module-frontend';
import { useEffect } from 'react';

import { ExperienceSummary } from '~/components/experiences';
import { listExperiencesFilteredHook } from '~/generated/clients/playground/hosts/experiences/PlaygroundHostsExperiences.hooks';
import { ExperienceStatus } from '~/generated/models/ExperienceStatus';
import { ExperienceWithTickets } from '~/generated/models/ExperienceWithTickets';
import DashboardSummary from './components/DashboardSummary';

function DashboardPage() {
  const { self } = useAuth();
  const {
    data: upcoming,
    calling: fetchingUpcoming,
    call: fetchUpcoming,
  } = listExperiencesFilteredHook(false);
  const {
    data: draft,
    calling: fetchingDraft,
    call: fetchDraft,
  } = listExperiencesFilteredHook(false);

  useEffect(() => {
    if (!self?.user || !self?.host) {
      return;
    }

    const query = {
      ids: { hostId: self.host.id },
    };

    if (!upcoming && !fetchingUpcoming) {
      fetchUpcoming({
        ...query,
        filters: {
          status: ExperienceStatus.Published,
          upcomingOnly: true,
          pageSize: 5,
        },
      });
    }

    if (!draft && !fetchingDraft) {
      fetchDraft({
        ...query,
        filters: {
          status: ExperienceStatus.Draft,
          pageSize: 10,
        },
      });
    }
  }, [self]);

  const renderExperiences = (list: ExperienceWithTickets[]) => {
    if (list.length === 0) {
      return <Article className="text-center">Nothing yet</Article>;
    }

    return (
      <>
        {list.map((experience) => {
          return (
            <ExperienceSummary
              experience={experience}
              key={experience.id.getValue()}
            />
          );
        })}
      </>
    );
  };

  return (
    <Page>
      <Section>
        <h2>Overview</h2>
        {self?.host && <DashboardSummary host={self.host} />}
      </Section>

      <Section>
        <h3>Upcoming Experiences</h3>
        <Loader horizontal loading={fetchingUpcoming}>
          {renderExperiences(upcoming?.result || [])}
        </Loader>
      </Section>

      <Section>
        <h3>Draft Experiences</h3>
        <Loader horizontal loading={fetchingDraft}>
          {renderExperiences(draft?.result || [])}
        </Loader>
      </Section>
    </Page>
  );
}

export default DashboardPage;
