import React from 'react';
import { tw } from '../../utils';

export type ArticleProps = {
  className?: string;
  bgColor?: string;
  children: React.ReactNode | Array<React.ReactNode> | undefined | null;
};

function Article({ children, className, bgColor }: ArticleProps) {
  bgColor = bgColor || 'bg-white';
  return (
    <article
      className={tw(
        'flex flex-col gap-5 p-3 rounded mt-5 ',
        bgColor,
        className,
      )}
    >
      {children}
    </article>
  );
}

export default Article;
