import Chart from 'react-apexcharts';
import * as React from 'react';
import { ApexOptions } from 'apexcharts';
import { MakeRequired } from '@cg/common/src/types';
import { GraphData } from '~/generated/models/GraphData';

export type Series = {
  name?: string;
  opacity?: number;
  color?: string;
  dashed?: boolean;
  type?: 'line' | 'area';
  data: GraphData;
};

type ApexChartSeries = ApexAxisChartSeries[number] & {
  data: { x: number; y: number }[];
};

type LineChartProps = {
  series: Series[];
  color?: string;
  precision?: number;
};

function LineChart({ series, color, precision }: LineChartProps) {
  const chartOptions: MakeRequired<ApexOptions, 'chart' | 'stroke' | 'colors'> =
    {
      chart: {
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      fill: {
        type: 'solid',
        opacity: [],
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        dashArray: [],
      },
      colors: [],
      xaxis: {
        type: 'datetime',
      },
    };
  if (color) {
    chartOptions.colors = [color];
  }
  if (precision || precision === 0) {
    chartOptions.yaxis = {
      labels: {
        formatter: (value: number) => value.toFixed(precision),
      },
    };
  }

  const formattedSeries: ApexAxisChartSeries = series.map((item, index) => {
    const thisSeries: ApexChartSeries = {
      data: [],
      name: item.name,
    };

    // @ts-ignore
    chartOptions.stroke.dashArray[index] = item.dashed ? 5 : 0;
    // @ts-ignore
    chartOptions.fill.opacity[index] = item.dashed ? 1 : 0.35;
    // @ts-ignore
    chartOptions.fill.opacity[index] =
      // @ts-ignore
      item.opacity ?? chartOptions.fill.opacity[index];
    chartOptions.colors[index] = item.dashed ? '#9CA3AF' : color;
    chartOptions.colors[index] = item.color ?? chartOptions.colors[index];
    if (item.type) {
      thisSeries.type = item.type;
    }

    item.data.x.forEach((x, i) => {
      thisSeries.data.push({
        x: new Date(x).getTime(),
        y: item.data.y[i],
      });
    });

    return thisSeries;
  });

  return <Chart options={chartOptions} series={formattedSeries} type="area" />;
}

export default LineChart;
