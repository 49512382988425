import { AppProvider } from '@cg/module-frontend/src/core';
import router from './routes';

import '@cg/module-frontend/src/theme/app.css';
import './app.css';

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default function Application() {
  return (
    <AppProvider
      router={{ router }}
      rollbarToken="4550e424f4224d44b8dcafa5013c67ed"
    />
  );
}
