/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { GraphData } from '../../../../models/GraphData';
import { HostId } from '@cg/common/src/ids/HostId';

export type GetHostGuestsGraphArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    filters?: {
        lastDays?: number,
    },
}

/**
 * @summary Endpoint for returning total unique guests host has had
 * @param {GetHostGuestsGraphArgs} args
 */
export const getHostGuestsGraph = (args: GetHostGuestsGraphArgs): Promise<HttpResult<GraphData>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/graphs/guests', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GetHostMoneyEarnedGraphArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    filters?: {
        lastDays?: number,
    },
}

/**
 * @summary Endpoint for returning the graphs of the money earned
 * @param {GetHostMoneyEarnedGraphArgs} args
 */
export const getHostMoneyEarnedGraph = (args: GetHostMoneyEarnedGraphArgs): Promise<HttpResult<GraphData>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/graphs/money', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GetHostTicketsSoldGraphArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    filters?: {
        lastDays?: number,
    },
}

/**
 * @summary Endpoint for returning total tickets host has sold
 * @param {GetHostTicketsSoldGraphArgs} args
 */
export const getHostTicketsSoldGraph = (args: GetHostTicketsSoldGraphArgs): Promise<HttpResult<GraphData>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/graphs/tickets', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
