import {
  CalendarIcon,
  CircularIconProgress,
  EyeIcon,
  MoneyIcon,
  TicketIcon,
  UserIcon,
} from '@cg/module-frontend/src/icons';
import { sizeToPx } from '@cg/module-frontend/src/constant.ts';
import * as DateUtils from '@cg/common/src/utils/DateUtils.ts';
import { Ticket } from '~/generated/models/Ticket.ts';
import { TicketTier } from '~/generated/models/TicketTier.ts';
import { Experience } from '~/generated/models/Experience.ts';

type ExperienceMetricsProps = {
  experience: Experience;
  tickets: Ticket[];
  tiers: TicketTier[];
};

export default function ExperienceMetrics({
  experience,
  tickets,
  tiers,
}: ExperienceMetricsProps) {
  const money = tickets.reduce((total, ticket) => {
    const tier = tiers.find((t) => t.id.isEqual(ticket.ticketTierId));
    const price = (tier?.price || 0) / 100;
    if (price === 0) {
      return total;
    }
    const fee = price * 0.029 + 0.3;

    return total + price - fee;
  }, 0);
  const dollar = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
  }).format(money);

  const participantsCount = tickets.length;
  const ticketsCount = tickets.filter((t) => !!t.paymentId).length;
  const totalTicketsCount = tiers.reduce(
    (total, tier) => total + tier.count,
    0,
  );

  return (
    <div className="grid grid-cols-3 gap-5 px-px mt-3 justify-center md:flex md:gap-5">
      <div className="flex gap-2.5 text-black items-center justify-center">
        <MoneyIcon className="size-6 flex items-center text-primary stroke-primary" />
        <span className="flex items-center">{dollar}</span>
      </div>

      <div className="flex gap-2.5">
        <div className="flex justify-center items-center my-auto">
          <CircularIconProgress
            percentage={(ticketsCount / totalTicketsCount) * 100}
            size="xs"
          >
            <TicketIcon className="size-5 text-primary" size={sizeToPx.xs} />
          </CircularIconProgress>
        </div>
        <span className="flex items-center">
          {ticketsCount}/{totalTicketsCount}
        </span>
      </div>

      <div className="flex gap-2.5 text-black items-center justify-center">
        <UserIcon className="size-6 flex items-center text-primary stroke-primary" />
        <span className="flex items-center">{participantsCount}</span>
      </div>

      <div className="flex gap-2.5 text-black items-center justify-center sm:justify-self-center">
        <EyeIcon className="size-6 flex items-center text-primary stroke-primary" />
        <span className="flex items-center">{experience.views}</span>
      </div>

      <div className="flex gap-2.5 text-black items-center justify-center sm:justify-self-center">
        <CalendarIcon className="size-6 flex items-center text-primary stroke-primary" />
        <span className="flex items-center">
          {!experience.startDate && 'TBD'}
          {experience.startDate &&
            DateUtils.dater(experience.startDate).fromNow()}
        </span>
      </div>
    </div>
  );
}
