import classNames from 'classnames';
import * as React from 'react';
import { ArrowRightIcon, ChevronRightIcon } from '../../icons';
import { Filters } from './types';

export type ChartCardProps = {
  title: string;
  isStatic?: boolean;
  isActive?: boolean;
  value?: string | number;
  onChange?: (value: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  additionalFilters?: React.ReactElement;
  // eslint-disable-next-line react/no-unused-prop-types
  render?: (filters: Filters) => void;
};

function ChartCard({
  title,
  isActive,
  value,
  onChange,
  isStatic,
}: ChartCardProps) {
  return (
    <button
      type="button"
      className={classNames(
        'flex gap-5 justify-between px-4 pt-2 pb-1.5 bg-white rounded border border-solid cursor-pointer mb-4',
        {
          'border-secondary': isActive,
        },
      )}
      onClick={() => {
        if (onChange) {
          onChange(title);
        }
      }}
    >
      <div className="flex flex-col py-1 items-start">
        <h3
          className={classNames({
            'text-primary': isActive,
          })}
        >
          {value}
        </h3>
        <span
          className={classNames({
            'font-bold text-primary': isActive,
          })}
        >
          {title}
        </span>
      </div>
      {!isStatic && isActive && (
        <ArrowRightIcon className="size-4 my-auto stroke-primary text-primary" />
      )}
      {!isStatic && !isActive && (
        <ChevronRightIcon className="size-4 my-auto text-grey-darker stroke-grey-darker" />
      )}
    </button>
  );
}

export default ChartCard;
