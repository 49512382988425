import * as events from './events.json';

export const isValid = (value: string): boolean => {
  return value in events;
};

export const getDescription = (value: string): string => {
  if (!isValid(value)) {
    throw new Error(`Unknown event ${value} provided`);
  }

  // @ts-ignore
  return events[value];
};

const EVENTS = Object.keys(events).reduce(
  (acc: Record<string, string>, key) => {
    acc[key] = key;
    return acc;
  },
  {},
);

export { EVENTS };
