import * as React from 'react';
import { useEffect } from 'react';
import { isEqual } from '@cg/common/src/utils/General';
import { ChartCardProps } from './ChartCard';
import { Filters } from './types';

export type ChartCardsProps = {
  children:
    | React.ReactElement<ChartCardProps>
    | React.ReactElement<ChartCardProps>[];
  onCardChange?: (title: string, index: number) => void;
  activeIndex?: number;
  filters?: Filters;
};

function ChartCards({
  children,
  onCardChange,
  filters,
  activeIndex,
}: ChartCardsProps) {
  const [prevFilters, setPrevFilters] = React.useState<Filters | undefined>(
    filters,
  );

  useEffect(() => {
    const child = React.Children.toArray(children)[0] as React.ReactElement;
    if (child.props.render && filters) {
      child.props.render(filters);
    }
  }, []);

  useEffect(() => {
    if (filters && prevFilters && !isEqual(filters, prevFilters)) {
      setPrevFilters(filters);
      const child = React.Children.toArray(children)[
        activeIndex ?? 0
      ] as React.ReactElement;
      if (child.props.render && filters) {
        child.props.render(filters);
      }
    }
  }, [filters, prevFilters]);

  const withProps = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onChange: (title: string) => {
          if (child.props.render && filters) {
            child.props.render(filters);
          }

          if (onCardChange) {
            onCardChange(title, index);
          }
        },
        isActive: activeIndex === index,
      });
    }

    return child;
  });

  return (
    <div className="grid grid-cols-2 md:grid-cols-1 gap-4 md:gap-0">
      {withProps}
    </div>
  );
}

export default ChartCards;
