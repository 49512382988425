import { Dropdown } from 'flowbite-react';
import * as React from 'react';
import { useEffect } from 'react';
import { Filters } from './types';

export type ChartGraphProps = {
  children: React.ReactElement;
  additionalFilters?: React.ReactNode;
  title?: string;
  onFiltered?: (filters: Filters) => void;
  hideDaySelector?: boolean;
  timelineDays: number[];
};

function ChartGraph({
  children,
  title,
  onFiltered,
  additionalFilters,
  hideDaySelector,
  timelineDays,
}: ChartGraphProps) {
  const [selected, setSelected] = React.useState('last 7 days');
  const [days, setDays] = React.useState(7);

  useEffect(() => {
    if (onFiltered) {
      onFiltered({ days });
    }
  }, [days]);

  return (
    <div className="flex flex-col px-5 pt-3.5 pb-8 bg-white rounded border border-secondary border-solid">
      <div className="flex gap-5 justify-between w-full text-base ">
        <h4 className="my-auto">{title}</h4>
        <div className="flex gap-3.5 justify-between items-center px-4 py-1 rounded ml-auto">
          <div className="flex gap-3.5 flex-shrink-0">{additionalFilters}</div>
          {!hideDaySelector && (
            <Dropdown label={selected} color="grey">
              {timelineDays.map((day) => {
                return (
                  <Dropdown.Item
                    key={day}
                    onClick={() => {
                      setSelected(`last ${day} days`);
                      setDays(day);
                    }}
                  >
                    last {day} days
                  </Dropdown.Item>
                );
              })}
              <Dropdown.Item
                className="last:focus:rounded-b"
                onClick={() => {
                  setSelected('all time');
                  setDays(3650);
                }}
              >
                all time
              </Dropdown.Item>
            </Dropdown>
          )}
        </div>
      </div>
      <div className="relative">
        {children}
        {/* <div className="absolute bottom-0 right-0 pr-5 pb-14"> */}
        {/*  <MaximizeIcon */}
        {/*    className="relative opacity-50 hover:opacity-100 cursor-pointer" */}
        {/*    onClick={() => setFullScreen(true)} */}
        {/*  /> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default ChartGraph;
