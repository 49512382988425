import { Navigate, createBrowserRouter } from 'react-router-dom';
import { lazy } from 'react';

import { CGRouteObject } from '@cg/module-frontend/src/router';

import { Unauthorized } from '@cg/module-frontend/src/errors';
import { ExperienceId, ExperienceSeriesId, UserId } from '@cg/common/src/ids';
import { Suspense } from '@cg/module-frontend/src/components';
import { featureFlagConstants } from '@cg/common/src/constants';
import { AppLayout } from '@cg/module-frontend/src/core';
import { fetchSelf } from '~/generated/clients/private/self/PrivateSelf.client';
import { fetchExperience } from '~/generated/clients/playground/hosts/experiences/PlaygroundHostsExperiences.client.ts';
import { Experience } from '~/generated/models/Experience.ts';
import { HostGuest } from '~/generated/models/HostGuest.ts';
import { fetchGuest } from '~/generated/clients/playground/hosts/guests/PlaygroundHostsGuests.client.ts';
import { ExperienceSeries } from '~/generated/models/ExperienceSeries';
import { fetchSeries } from '~/generated/clients/playground/hosts/series/PlaygroundHostsSeries.client';

import { DashboardLayout } from '~/app/layout';
import DashboardPage from '~/pages/dashboard';
import HomePage from '~/pages/home';

const EditorLayout = lazy(() => import('~/app/layout/editor'));
const ExperiencesPage = lazy(() => import('~/pages/dashboard/experiences'));
const ExperiencePage = lazy(
  () => import('~/pages/dashboard/experiences/experience'),
);
const ExperienceEditPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/edit'),
);
const OrganizersPage = lazy(
  () => import('~/pages/dashboard/settings/organizers'),
);
const ExperienceEditorSettingsPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/edit/settings'),
);
const ExperienceEditorTicketsPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/edit/tickets'),
);
const ExperienceEditorQuestionPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/edit/questions'),
);
const ProfilePage = lazy(() => import('~/pages/dashboard/profile'));
const GuestsPage = lazy(() => import('~/pages/dashboard/guests'));
const GuestPage = lazy(() => import('~/pages/dashboard/guests/guest'));
const PaymentsPage = lazy(() => import('~/pages/dashboard/settings/payments'));
const DeveloperPage = lazy(
  () => import('~/pages/dashboard/settings/developer'),
);
const CheckInPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/check-in'),
);
const SalesPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/sales'),
);
const ContactGuestsPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/contact'),
);
const SeriesListPage = lazy(() => import('~/pages/dashboard/series'));
const SeriesPage = lazy(() => import('~/pages/dashboard/series/serie'));

const routes: CGRouteObject = {
  path: '/',
  id: 'root',
  element: <AppLayout />,
  children: [
    {
      index: true,
      element: <HomePage />,
    },
    {
      path: '/dashboard',
      id: 'dashboard',
      element: <DashboardLayout />,
      handle: {
        requiresAuth: true,
        featureFlags: [featureFlagConstants.PLAYGROUND_ACCESS],
        crumb: () => ({
          title: 'Dashboard',
          key: 'dashboard',
        }),
      },
      children: [
        {
          index: true,
          element: <Navigate to="home" replace />,
        },
        {
          path: '/dashboard/home',
          id: 'dashboard/home',
          element: <DashboardPage />,
          handle: {
            crumb: () => ({
              title: 'Home',
              key: 'home',
            }),
          },
        },
        {
          path: '/dashboard/guests',
          id: 'dashboard/guests',
          handle: {
            crumb: () => ({
              title: 'Guests',
              key: 'guests',
            }),
          },
          children: [
            {
              index: true,
              element: (
                <Suspense>
                  <GuestsPage />
                </Suspense>
              ),
            },
            {
              path: '/dashboard/guests/:userId',
              children: [],
              id: 'dashboard/guests/guest',
              loader: async ({ params }) => {
                const self = await fetchSelf({});
                if (!self.succeeded || !self.payload.host) {
                  throw new Unauthorized(
                    'You are not authorized to view this page',
                  );
                }
                const guest = await fetchGuest({
                  ids: {
                    userId: new UserId(params.userId),
                    hostId: self.payload.host.id,
                  },
                });

                return guest.payload;
              },
              handle: {
                crumb: (guest: HostGuest) => ({
                  title: `${guest.firstName} ${guest.lastName}`,
                  key: guest.id.getValue(),
                }),
              },
              element: (
                <Suspense>
                  <GuestPage />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: '/dashboard/profile',
          id: 'dashboard/profile',
          element: (
            <Suspense>
              <ProfilePage />
            </Suspense>
          ),
          handle: {
            crumb: () => ({
              title: 'Profile',
              key: 'profile',
            }),
          },
        },
        {
          path: '/dashboard/settings',
          id: 'dashboard/settings',
          handle: {
            crumb: () => ({
              title: 'Settings',
              key: 'settings',
            }),
          },
          children: [
            {
              index: true,
              element: <Navigate to="/dashboard/settings/payments" replace />,
            },
            {
              path: '/dashboard/settings/payments',
              id: 'dashboard/setttings/payments',
              element: (
                <Suspense>
                  <PaymentsPage />
                </Suspense>
              ),
              handle: {
                crumb: () => ({
                  title: 'Payments',
                  key: 'payments',
                }),
              },
            },
            {
              path: '/dashboard/settings/organizers',
              id: 'dashbaord/settingss/organizers',
              element: (
                <Suspense>
                  <OrganizersPage />
                </Suspense>
              ),
              handle: {
                crumb: () => ({
                  title: 'Users',
                  key: 'users',
                }),
              },
            },
            {
              path: '/dashboard/settings/developer',
              id: 'dashboard/settings/developer',
              element: (
                <Suspense>
                  <DeveloperPage />
                </Suspense>
              ),
              handle: {
                crumb: () => ({
                  title: 'Developer Settings',
                  key: 'developer',
                }),
              },
            },
          ],
        },
        {
          path: '/dashboard/series',
          id: 'dashboard/series',
          handle: {
            crumb: () => ({
              title: 'Experience Series',
              key: 'series',
            }),
            featureFlags: [featureFlagConstants.PLAYGROUND_SERIES],
          },
          children: [
            {
              index: true,
              element: (
                <Suspense>
                  <SeriesListPage />
                </Suspense>
              ),
            },
            {
              path: '/dashboard/series/:experienceSeriesId',
              id: 'dashboard/series/serie',
              element: (
                <Suspense>
                  <SeriesPage />
                </Suspense>
              ),
              handle: {
                crumb: (series: ExperienceSeries) => ({
                  title: series.name,
                  key: series.id.getValue(),
                }),
              },
              loader: async ({ params }) => {
                const self = await fetchSelf({});
                if (!self.succeeded || !self.payload.host) {
                  throw new Unauthorized(
                    'You are not authorized to view this page',
                  );
                }
                const series = await fetchSeries({
                  ids: {
                    hostId: self.payload.host.id,
                    experienceSeriesId: new ExperienceSeriesId(
                      params.experienceSeriesId,
                    ),
                  },
                });

                return series.payload;
              },
            },
          ],
        },
        {
          path: '/dashboard/experiences',
          id: 'dashboard/experiences',
          handle: {
            crumb: () => ({
              title: 'Experiences',
              key: 'experiences',
            }),
          },
          children: [
            {
              index: true,
              element: (
                <Suspense>
                  <ExperiencesPage />
                </Suspense>
              ),
            },
            {
              path: '/dashboard/experiences/:experienceId',
              id: 'dashboard/experiences/experience',
              loader: async ({ params }) => {
                const self = await fetchSelf({});
                if (!self.succeeded || !self.payload.host) {
                  throw new Unauthorized(
                    'You are not authorized to view this page',
                  );
                }
                const experience = await fetchExperience({
                  ids: {
                    experienceId: new ExperienceId(params.experienceId),
                    hostId: self.payload.host.id,
                  },
                });

                return experience.payload;
              },
              handle: {
                crumb: (experience: Experience) => ({
                  title: experience.title,
                  key: experience.id.getValue(),
                }),
              },
              children: [
                {
                  index: true,
                  element: (
                    <Suspense>
                      <ExperiencePage />
                    </Suspense>
                  ),
                },
                {
                  path: '/dashboard/experiences/:experienceId/check-in',
                  id: 'dashboard/experiences/experience/check-in',
                  element: (
                    <Suspense>
                      <CheckInPage />
                    </Suspense>
                  ),
                },
                {
                  path: '/dashboard/experiences/:experienceId/sales',
                  id: 'dashboard/experiences/experience/sales',
                  element: (
                    <Suspense>
                      <SalesPage />
                    </Suspense>
                  ),
                },
                {
                  path: '/dashboard/experiences/:experienceId/contact',
                  id: 'dashboard/experiences/experience/contact',
                  element: (
                    <Suspense>
                      <ContactGuestsPage />
                    </Suspense>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/dashboard/experiences/:experienceId/edit',
      id: 'dashboard/experiences/experience/edit',
      element: (
        <Suspense>
          <EditorLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense>
              <ExperienceEditPage />
            </Suspense>
          ),
        },
        {
          path: '/dashboard/experiences/:experienceId/edit/settings',
          id: 'dashboard/experiences/experience/edit/settings',
          element: (
            <Suspense>
              <ExperienceEditorSettingsPage />
            </Suspense>
          ),
        },
        {
          path: '/dashboard/experiences/:experienceId/edit/tickets',
          id: 'dashboard/experiences/experience/edit/tickets',
          element: (
            <Suspense>
              <ExperienceEditorTicketsPage />
            </Suspense>
          ),
        },
        {
          path: '/dashboard/experiences/:experienceId/edit/questions',
          id: 'dashboard/experiences/experience/edit/questions',
          element: (
            <Suspense>
              <ExperienceEditorQuestionPage />
            </Suspense>
          ),
        },
      ],
    },
  ],
};

export default createBrowserRouter([routes]);
