import * as React from 'react';
import { ReactElement } from 'react';
import ChartCards, { ChartCardsProps } from './ChartCards';
import ChartGraph, { ChartGraphProps } from './ChartGraph';
import { Filters } from './types';

type GraphBoardProps = {
  children: React.ReactElement<ChartCardsProps | ChartGraphProps>[];
  onChange?: (title: string, days: number) => void;
};

function GraphBoard({ children, onChange }: GraphBoardProps) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const mapped = React.Children.map(children, (child) => child).find(
    (child) => child.type === ChartCards,
  );
  // @ts-ignore
  const initTitle = mapped?.props.children?.[0].props.title;

  const [title, setTitle] = React.useState(initTitle);
  const [filters, setFilters] = React.useState<Filters>({ days: 7 });

  const cards: ReactElement[] = [];
  const graphs: ReactElement[] = [];
  let customFilters: React.ReactElement | undefined;

  React.Children.forEach(children, (child) => {
    if (child.type === ChartCards) {
      const cardChildren = React.Children.toArray(child.props.children)[
        activeIndex
      ] as React.ReactElement;
      customFilters = cardChildren.props.additionalFilters;

      const card = React.cloneElement(child, {
        onCardChange: (value: string, index: number) => {
          setTitle(value);
          setActiveIndex(index);
          if (onChange) {
            onChange(value, filters.days);
          }
        },
        filters,
        activeIndex,
      });
      cards.push(card);
    }
  });

  React.Children.forEach(children, (child) => {
    if (child.type === ChartGraph) {
      const existingFilters = (child as React.ReactElement<ChartGraphProps>)
        .props.additionalFilters;
      const additionalFilters = (
        <>
          {existingFilters}
          {customFilters}
        </>
      );

      const graph = React.cloneElement(child, {
        title,
        additionalFilters,
        onFiltered: (value: Filters) => {
          setFilters(value);
          if (onChange) {
            onChange(title, value.days);
          }
        },
      });
      graphs.push(graph);
    }
  });

  return (
    <div className="flex flex-col md:space-x-4 md:flex-row justify-center p-3 bg-white rounded">
      <div className="w-full md:w-1/4">{cards}</div>
      <div className="w-full md:w-3/4">{graphs}</div>
    </div>
  );
}

export default GraphBoard;
