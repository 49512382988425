import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '@cg/module-frontend';
import { Breadcrumb, Footer } from '@cg/module-frontend/src/components';
import * as React from 'react';

import { featureFlagConstants } from '@cg/common/src/constants';
import { boxConfig } from '@cg/module-frontend/src/config';
import { TopNav } from './TopNav';
import { LeftNav } from '~/app/layout/dashboard/LeftNav.tsx';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const DashboardLayout = React.memo(function DashboardLayout() {
  const { hasFlag, fetchingSelf, self } = useAuth();
  useEffect(() => {
    if (fetchingSelf) {
      return;
    }

    if (!hasFlag(featureFlagConstants.PLAYGROUND_ACCESS)) {
      window.location.href = `${boxConfig.baseUrls.commonGround}/become-a-host`;
    }
    // user is not assigned to a host
    if (!self?.host) {
      window.location.href = boxConfig.baseUrls.playGround;
    }
  }, [fetchingSelf, self]);

  return (
    <>
      <main className="flex h-screen">
        <LeftNav />

        <div className="flex flex-col w-full overflow-x-auto bg-grey">
          <TopNav />
          <div className="flex-grow w-full px-4 mt-5 pb-5 border-b border-grey-darker">
            <Breadcrumb />
            <div className="-mt-5 min-w-0">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <ScrollToTop />
    </>
  );
});

export default DashboardLayout;
