import * as React from 'react';
import classNames from 'classnames';
import { Link, UIMatch } from 'react-router-dom';
import { ArrowRightIcon, ChevronDownIcon } from '../../icons';

export type Nav = {
  to: string;
  icon?: (active: boolean) => React.ReactElement;
  title: string;
  children?: Nav[];
};

type NavLinksProps = {
  isNested?: boolean;
  matches: UIMatch[];
  navs: Nav[];
  isCollapsed: boolean;
};

function NavigationLinks({
  isNested,
  matches,
  navs,
  isCollapsed,
}: NavLinksProps) {
  return (
    <ul
      className={classNames('flex flex-col mt-6 font-medium text-black', {
        'ml-3 space-y-6': isNested,
        'space-y-8': !isNested,
      })}
    >
      {navs.map((nav) => {
        const isActive = matches.find((m) => m.pathname === nav.to);
        const hasChildren = (nav.children?.length || 0) > 0;

        return (
          <li
            key={nav.to}
            className={classNames('flex flex-col justify-center', {
              'mx-auto': isCollapsed,
              'pl-4': !isCollapsed,
              'pr-4': !isNested && !isCollapsed,
              'font-semibold': isActive,
              'text-primary': isActive,
            })}
          >
            <Link to={nav.to} className="flex justify-between items-center">
              <div className="flex gap-2.5">
                {nav.icon && nav.icon(isActive !== undefined)}
                {!isCollapsed && <div>{nav.title}</div>}
              </div>
              {!isCollapsed && isActive && !hasChildren && (
                <ArrowRightIcon className="flex items-center text-primary size-4" />
              )}
              {!isCollapsed && isActive && hasChildren && (
                <ChevronDownIcon className="flex items-center text-primary size-4" />
              )}
            </Link>
            {!isCollapsed && isActive && nav.children?.length ? (
              <NavigationLinks
                matches={matches}
                navs={nav.children}
                isNested
                isCollapsed={isCollapsed}
              />
            ) : null}
          </li>
        );
      })}
    </ul>
  );
}

export default NavigationLinks;
