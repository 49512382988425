import * as React from 'react';
import { Size, sizeToPx } from '../constant';

type CircularIconProgressProps = {
  percentage: number;
  children: React.ReactElement;
  size: Size;
};

function CircularIconProgress({
  percentage,
  children,
  size,
}: CircularIconProgressProps) {
  const width = sizeToPx[size] + 20;
  const height = sizeToPx[size] + 20;
  const strokeWidth = 2;
  const radius = Math.min(width, height) / 2 - strokeWidth * 2;
  const cx = width / 2;
  const cy = height / 2;
  const circumference = 2 * Math.PI * radius;

  percentage = Math.min(100, Math.max(0, percentage));
  percentage = Number.isNaN(percentage) ? 0 : percentage;
  const progress = circumference - (percentage / 100) * circumference;

  return (
    <div
      className="relative flex items-center justify-center"
      style={{ width, height }}
    >
      <svg width={width} height={height}>
        <circle
          className="stroke-grey"
          cx={cx}
          cy={cy}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
        />
        <circle
          cx={cx}
          cy={cy}
          r={radius}
          fill="none"
          className="stroke-primary"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={progress}
          transform={`rotate(-90 ${cx} ${cy})`}
        />
      </svg>
      <div className="absolute">{children}</div>
    </div>
  );
}

export { CircularIconProgress };
