import { createContext, useContext } from 'react';
import { ExperienceMediaId, TicketTierId } from '@cg/common/src/ids';
import { Experience } from '~/generated/models/Experience';
import { Host } from '~/generated/models/Host';
import { Address } from '~/generated/models/Address';
import { ExperienceConfirmation } from '~/generated/models/ExperienceConfirmation.ts';
import { ExperienceCTAPayload } from '~/generated/models/ExperienceCTAPayload.ts';
import { CreateTicketTierRequest } from '~/generated/models/CreateTicketTierRequest.ts';
import { CreateExperienceMediaRequest } from '~/generated/models/CreateExperienceMediaRequest.ts';

export type ExperienceMedia = CreateExperienceMediaRequest & {
  id?: ExperienceMediaId;
  action?: 'create' | 'remove' | 'update';
  uuid: string;
};

export type TicketTier = CreateTicketTierRequest & {
  id?: TicketTierId;
  sold?: number;
  action?: 'create' | 'remove' | 'update';
  uuid: string;
};

export type ResourcesType =
  | 'experience'
  | 'address'
  | 'medias'
  | 'tiers'
  | 'confirmation';

type Resources = {
  experience: Experience & {
    rescheduleReason?: string;
    refundEndDate?: Date;
  };
  address: Address;
  medias: ExperienceMedia[];
  tiers: TicketTier[];
  confirmation: {
    text: string;
    cta?: ExperienceCTAPayload;
  };
};

export type UpdateResourceType = <R extends ResourcesType>(
  type: ResourcesType,
  resource: Resources[R],
) => void;

export type Issue = {
  resource: string;
  dataPath: string;
  friendlyName: string;
  message: string;
  topLevel?: boolean;
  unSavable?: boolean;
  level: 'error' | 'warning';
};

export type Issues = {
  [key in string]: Issue;
};

export type EditorContextType = {
  // Resources
  experience?: Experience;
  confirmation?: ExperienceConfirmation;
  address?: Address;
  tiers: TicketTier[];
  medias: ExperienceMedia[];
  updateResource: UpdateResourceType;
  updateFailed: boolean;

  loading: boolean;
  isDirty: boolean;
  host?: Host;

  issues: Issues;
  getIssue: (resource: string) => Issue | null;

  publish: () => void;
  published: boolean;
  isPublished: boolean;
  isPublishable: boolean;

  isSaving: boolean;
};

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

export const EditorContext = createContext<EditorContextType>({
  // resources
  experience: undefined,
  confirmation: undefined,
  address: undefined,
  tiers: [],
  medias: [],
  updateResource: stub,
  updateFailed: false,

  // state
  loading: false,
  published: false,
  isPublished: false,
  isPublishable: false,
  isDirty: false,

  // error states
  issues: {},
  getIssue: stub,

  // other data
  host: undefined,

  publish: stub,

  isSaving: false,
});

export function useEditor() {
  return useContext(EditorContext);
}
