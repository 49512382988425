/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { HostTotalGuests } from '../../../../models/HostTotalGuests';
import { HostId } from '@cg/common/src/ids/HostId';
import { HostMoneyEarned } from '../../../../models/HostMoneyEarned';
import { HostTicketsSold } from '../../../../models/HostTicketsSold';

export type GetHostGuestsAggregatesArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
}

/**
 * @summary Endpoint for returning total unique guests host has had
 * @param {GetHostGuestsAggregatesArgs} args
 */
export const getHostGuestsAggregates = (args: GetHostGuestsAggregatesArgs): Promise<HttpResult<HostTotalGuests>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/aggregates/guests', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GetHostMoneyEarnedAggregatesArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
}

/**
 * @summary Endpoint for returning total money host has earned
 * @param {GetHostMoneyEarnedAggregatesArgs} args
 */
export const getHostMoneyEarnedAggregates = (args: GetHostMoneyEarnedAggregatesArgs): Promise<HttpResult<HostMoneyEarned>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/aggregates/money', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GetHostTicketsSoldAggregatesArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
}

/**
 * @summary Endpoint for returning total tickets host has sold
 * @param {GetHostTicketsSoldAggregatesArgs} args
 */
export const getHostTicketsSoldAggregates = (args: GetHostTicketsSoldAggregatesArgs): Promise<HttpResult<HostTicketsSold>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/aggregates/tickets', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
