import { Tooltip as FBTooltip } from 'flowbite-react';
import React from 'react';

type Props = {
  className?: string;
  content: React.ReactNode;
  children?: React.ReactNode;
  icon?: React.ReactNode;
};

export default function ToolTip({ content, children, icon, className }: Props) {
  const body = children ?? (
    <div className="flex items-center justify-center w-5 h-5 bg-secondary rounded-full text-sm text-primary ml-2">
      {icon}
    </div>
  );

  return (
    <FBTooltip
      className={className}
      content={content}
      /* eslint-disable-next-line react/style-prop-object */
      style="light"
    >
      {body}
    </FBTooltip>
  );
}
