import {
  ChartBoard,
  ChartCard,
  ChartCards,
  ChartGraph,
  LineChart,
} from '@cg/module-frontend/src/components/chartboard';
import { Article, Loader } from '@cg/module-frontend/src/components';
import { useEffect, useState } from 'react';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import {
  getHostGuestsAggregatesHook,
  getHostTicketsSoldAggregatesHook,
} from '~/generated/clients/playground/hosts/aggregates/PlaygroundHostsAggregates.hooks';
import { Host } from '~/generated/models/Host';
import {
  getHostGuestsGraphHook,
  getHostTicketsSoldGraphHook,
} from '~/generated/clients/playground/hosts/graphs/PlaygroundHostsGraphs.hooks';
import { GraphData } from '~/generated/models/GraphData';

type DashboardSummaryProps = {
  host: Host;
};

function DashboardSummary({ host }: DashboardSummaryProps) {
  const [graph, setGraph] = useState<GraphData | null>(null);

  // Aggregate data
  const { data: ticketsSoldCount, calling: fetchingTicketsSoldCount } =
    getHostTicketsSoldAggregatesHook(true, {
      ids: {
        hostId: host.id,
      },
    });
  const { data: guestsCount, calling: fetchingGuestsCount } =
    getHostGuestsAggregatesHook(true, {
      ids: {
        hostId: host.id,
      },
    });

  // Graph data
  const {
    data: ticketsSoldGraph,
    call: fetchTicketsSoldGraph,
    calling: fetchingTicketsSoldGraph,
  } = getHostTicketsSoldGraphHook(false);
  const {
    data: guestsGraph,
    call: fetchGuestsGraph,
    calling: fetchingGuestsGraph,
  } = getHostGuestsGraphHook(false);

  const onCardChange = async (title: string, days: number) => {
    if (title === 'Tickets Sold') {
      await fetchTicketsSoldGraph({
        ids: { hostId: host.id },
        filters: {
          lastDays: days,
        },
      });
    }

    if (title === 'Total Guests') {
      await fetchGuestsGraph({
        ids: { hostId: host.id },
        filters: {
          lastDays: days,
        },
      });
    }
  };

  // First page load
  useEffect(() => {
    onCardChange('Tickets Sold', 7);
  }, []);

  // On Ticket changed
  useEffect(() => {
    if (ticketsSoldGraph) {
      const xData = ticketsSoldGraph.x.map((x) =>
        DateUtils.dater(x).format('YYYY-MM-DD'),
      );

      setGraph({ x: xData, y: ticketsSoldGraph.y });
    }
  }, [ticketsSoldGraph]);
  useEffect(() => {
    if (guestsGraph) {
      const xData = guestsGraph.x.map((x) =>
        DateUtils.dater(x).format('YYYY-MM-DD'),
      );

      setGraph({ x: xData, y: guestsGraph.y });
    }
  }, [guestsGraph]);

  const callingAggregates = fetchingTicketsSoldCount || fetchingGuestsCount;
  const callingGraph = fetchingTicketsSoldGraph || fetchingGuestsGraph;

  return (
    <Loader loading={callingAggregates} horizontal>
      <Article>
        <ChartBoard onChange={onCardChange}>
          <ChartCards key="chart-cards">
            <ChartCard title="Tickets Sold" value={ticketsSoldCount?.count} />
            <ChartCard title="Total Guests" value={guestsCount?.count} />
          </ChartCards>

          <ChartGraph timelineDays={[7, 30, 90, 365]} key="chart-graph">
            <Loader loading={callingGraph} horizontal>
              {graph && (
                <LineChart series={[{ data: graph }]} color="#D8607A" />
              )}
            </Loader>
          </ChartGraph>
        </ChartBoard>
      </Article>
    </Loader>
  );
}
export default DashboardSummary;
